.page_wrapper {
	.page_content { margin-top: 30px; }
	@include media-breakpoint-up(lg) {
		.page_welcome { @include make-col(42,100); }
		.page_content { 
			@include make-col(58,100); 
			margin-top: 0;
		}
	}
	@include media-breakpoint-up(xl) {
		.page_welcome { @include make-col(35,100); }
		.page_content { @include make-col(65,100); }
	}
}

.service_page_wrapper,
.page_wrapper_half {
	.page_content { margin-top: 30px; }
	@include media-breakpoint-up(lg) {
		.page_welcome { @include make-col(50,100); }
		.page_content { 
			@include make-col(50,100); 
			margin-top: 0;
		}
	}
}

.page_welcome {
	.list-50 {
		@include media-breakpoint-up(sm) {
			display: flex;
			flex-wrap: wrap;
			li { width: 50%; }
		}
	}
}

.about_faqs {
	.main h2:not(:first-of-type) { margin-top: 45px }
}