.menu_jump {
	padding: 30px 30px 0;

	&.sticky {
		position: fixed;
		top: 0;
		background-color: white;
		width: 100%;
		z-index: 10;
	}
}

.menu-jump {
	@include list-inline;
	margin-bottom: 0;
	li { margin-bottom: 15px; }
	a {
		@include btn(10px 15px, 4px);	
		@include btn-solid(rgba($brand-primary-dark, 0.5), $brand-secondary, white, false, true);
		border-color: rgba(white,0.2);
		text-shadow: 0 2px 4px rgba(black,0.5);
	}
}

.too_holder { 
	position: relative; 
	.menu-group-title {
		margin-top: 0;
	}
	@include media-breakpoint-up(md) {
		.menu-group-title {
			margin-top: 30px;
		}
	}
}

.top_online_order {
	margin-top: 30px !important;
	@include font-size(18);
	line-height: 1.15;
	letter-spacing: -0.02em;
	text-align: center;
	font-weight: bold;
	@include media-breakpoint-up(md) {
		margin: 18px 20px 0 0 !important;
		max-width: 50%;
		float: right;
		text-align: right;
		z-index: 10;
	}
}

.menu_group_wrapper {
	padding: 20px;
	.row { margin: 0; }
}

	.menu_wrapper {
		padding: 0;
		background-color: $body-bg;
		border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
		border: solid 5px $body-bg;
		box-shadow: 0 45px 35px -35px black;
		> div {
			@include make-col-ready;
			padding: 15px;
		}
		@include media-breakpoint-up(md) {
			padding: 15px;
		}
		@include media-breakpoint-up(lg) {
			.menu_group_title { @include make-col(3,5); padding-bottom: 0; }
			.menu_group_items { padding-top: 0; }
			.menu-group-list {
				columns: 2;
				li {
					-webkit-column-break-inside: avoid;
					page-break-inside: avoid;
					break-inside: avoid;				
				}
			}
		}
	}

	.menu_group_title { 
		padding-top: 45px; 
		padding-bottom: 0;
		@include media-breakpoint-up(lg) {
			margin-bottom: 30px;
		}
	}

.menu_subgroup {
	padding: 30px 20px 15px;
	margin-left: -15px;
	margin-right: -15px;
	border: 5px solid darken($body-bg, 6%);
	border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
	background-color: darken($body-bg, 6%);
	&--burgers { margin-bottom: 45px; }
	&--sides { margin-top: 20px; }
}

	.burger-note {
		margin: 10px 0 20px 10px;
		@include media-breakpoint-up(lg) {
			max-width: 60%;
		}
	}

	.menu-group-title {
		margin-bottom: 0;
		+p { margin-top: 15px; }
	}

	.menu-group-sub-title {
		margin: 0 0 0 10px;
		font-family: $font-family-headings;
		font-weight: normal;
		letter-spacing: 0.2em;

		.menu_wine & {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin-bottom: 15px;
			&:not(:first-of-type) { margin-top: 30px; }
			> * { width: 50%; }
			.double-price-qualifier {
				white-space: nowrap;
				padding-right: 20px;
				font-family: $font-family-body;
				@include font-size(19);
				letter-spacing: -0.02em;
				font-weight: 600;
				font-stretch: 75%;
				text-align: right;
				text-transform: uppercase;
				span {
					display: inline-block;
					&.priceBottle { width: 70px; }
					+span { margin-left: 10px; }
				}
			}
			@include media-breakpoint-up(lg) {
				margin-bottom: 30px;
				&:not(:first-of-type) { margin-top: 45px; }
			}
		}

	}

	.menu-column-wine-fix {
		.double-price-qualifier { display: none; }
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			.double-price-qualifier { display: inline-block; }
		}
	}

	.menu-group-list {
		@include list-unstyled;	
	} 

		.menu-item { 
			padding: 20px;
			&:nth-child(even) {
				background-color: rgba($brand-accent,0.2);
				border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
			}
			&--title {
				margin: 0;
				@include font-size(19);
				font-family: $font-family-sans-serif;
				font-weight: $body-weight-bolder;
				font-stretch: 75%;
				word-spacing: 0.075em;
				letter-spacing: -0.03em;
				line-height: 1;
			}
			&--price {
				display: block;
				small {
					@include font-size(15);
					font-weight: $body-weight-bold;
					text-transform: uppercase;
				}
				@include media-breakpoint-up(sm) {
					display: inline-block;
					float: right;
				}
			}
			&--description {
				margin: 15px 0 0;
				max-width: 82%;
				font-size: 95%;
				line-height: 1.3;
			} 
			&--addon-break {
				margin: 15px 0;
				box-shadow: 0 1px 0 0 rgba(white,0.1);
			}
			&--addon {
				margin: 0 0 0 10px;
				&::before {
					content: "- ";
				}
				&:first-of-type {
					margin-top: 15px;
				}
			} 
		}

		.menu-item--title {
			@include media-breakpoint-up(lg) {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				.menu-item--price {
					white-space: nowrap;
					text-align: right;
				}
				//> * { min-width: 50%; }
			}
			@include media-breakpoint-only(lg) {
				> * { min-width: 50%; }
			}
		}

		.menu_buckets {
			.menu-item--title {
				@include media-breakpoint-only(lg) {
					display: block;
				}
				small {
					font-weight: $heading-weight-normal;
					@include font-size(16);
				}
			}
		}

		.menu_wine {
			.menu-item--title {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				font-weight: 600;
				.the-title { padding-right: 15px; }
				.menu-item--price {
					white-space: nowrap;
					font-family: $font-family-body;
					@include font-size(19);
					letter-spacing: -0.02em;
					font-stretch: 75%;
					text-align: right;
					text-transform: uppercase;
					span {
						display: inline-block;
						width: 70px;
						+span { margin-left: 10px; }
					}
				}				
			}
		}

.menu_return {
	text-align: right;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	padding: 15px;
	a { 
		display: inline-block;
		padding: 13px 10px 11px;
		border-radius: 30px;
		background-color: white;
		line-height: 1;
		transition: background-color 300ms;
		&:hover {
			transition: background-color 300ms;
			background-color: $brand-secondary;
			svg { 
				transition: background-color 300ms;
				fill: white; 
			}
		}
	}
	svg { fill: $brand-secondary; }
}

.menu-item--addon-group {
	margin-top: 15px;
}
