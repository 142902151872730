.widget {
	margin-bottom: 30px;
	padding: 20px;
	border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;

	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }

	&-title {
		margin: 0 0 10px;
	}

	&-primary { background-color: rgba($brand-primary,0.2); }
	&-secondary { background-color: rgba($brand-secondary,0.2); }
	&-highlight { background-color: rgba($brand-highlight,0.2); }
	&-accent { background-color: rgba($brand-accent,0.2); }
	&-success { background-color: rgba($color-success,0.2); }
	&-danger { background-color: rgba($color-danger,0.2); }
	&-warning { background-color: rgba($color-warning,0.2); }
	&-info { background-color: rgba($color-info,0.2); }

}

	.widget_google {
		padding: 0;
		@include font-size(14);
		font-weight: $body-weight-bolder;
		font-stretch: 75%;
		letter-spacing: -0.03em;
		text-align: center;
		text-transform: uppercase;
		word-spacing: 0.075em;
		color: white;
		line-height: 0;
		* { vertical-align: middle; }
		span * { vertical-align: middle; }
		strong { 
			display: block;
			margin-top: 5px;
			line-height: 1; 
		}
		@include media-breakpoint-only(sm) {
			strong { margin: 0 0 0 15px; }
		}
		@include media-breakpoint-up(sm) {
			@include font-size(18);
		}
		@include media-breakpoint-between(sm,md) {
			br { display: none; }
			strong { display: inline-block; }
		}
		@include media-breakpoint-only(md) {
			display: flex;
			align-items: center;
			justify-content: center;
			.logo-google { width: 27px; height: 27px; }
			.icon-stars { 
				width: (84.5 * 1.3) * 1px;
				height: (15.6 * 1.3) * 1px;
			}
		}
	}