.page_title {
	padding-top: 60px;
	border-top: 1px solid $brand-primary-light;
	border-bottom: 1px solid $brand-primary-light;
	//background-color: rgba($brand-highlight, 0.1);
	/* border-bottom: 3px solid $brand-primary-dark;
	border-top: 3px solid $brand-primary-light; */
	background-color: rgba($brand-primary-dark,0.6);
	background-image: url("/_/images/layout/page-title.png");
	box-shadow: inset 0 0 20px 10px rgba(black,0.9);
	//backdrop-filter: blur(2px);
	background-attachment: fixed;

	.page-title {
		margin: 0; 
		text-transform: uppercase;
		text-shadow: 6px 6px 3px black;
	}
	@include media-breakpoint-up(md) {	
		padding-top: 120px;
	}
	@include media-breakpoint-up(lg) {	
		padding-top: 180px;
	}
	
}
