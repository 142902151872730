.main {
	
	@include media-breakpoint-up(md) {

		.card--right {
			float: right;
			margin: 0 0 15px 15px;
			width: 50%;
		}

	}

}