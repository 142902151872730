$table-bg:                  $white;
$table-border-width:        1px;
$table-border-color:        darken($body-bg, 15%);
$table-accent-bg:           darken($body-bg, 4%);
$table-hover-bg:            #e5e5e5;
$table-cell-padding:        10px;

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    line-height: 1em;
    //background-color: $table-bg;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;    
    }

    th,
    td {
        padding: $table-cell-padding;
        vertical-align: top;
        //border-top: $table-border-width solid $table-border-color;
    }

    thead th {
        vertical-align: bottom;
        //border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
        //border-top: (2 * $table-border-width) solid $table-border-color;
    }

}

.table-bordered {
    border: $table-border-width solid $table-border-color;

    th, td { border: $table-border-width solid $table-border-color; }

    thead {
        th, td { border-bottom-width: (2 * $table-border-width); }
    }
}

.table-striped {
    tbody tr:nth-of-type(odd) { background-color: $table-accent-bg; }
}

.table-hover {
    tbody tr:hover {
        background-color: $table-hover-bg;
        cursor: pointer;
    }
}

.table-responsive {
    @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        > .table-bordered {
            border: 0;
        }        
    }
}

.event-table {
	line-height: 1.2;

	tr {
		&:nth-child(even) {
			background-color: darken($body-bg,5%);
		}
	}

	@include media-breakpoint-only(xs) {
		display: block;
		border: none;
		width: 100%;
		tbody, tr, td { 
			display: block; 
			width: 100%;
		}
		tr { 
			margin: 10px 0; 
		}
		td {
			padding: 5px 15px;
			&.event_date { padding-top: 10px; }
			&.event_act { padding-bottom: 10px; }
		}
	}

	@include media-breakpoint-up(sm) {
		td { padding: 15px;}
		tr:nth-child(odd) td {
			padding: 30px 15px;
		}
	}

}

	.event {
		&-day,
		&-act {
			display: block;
			font-weight: $body-weight-bolder;
			text-transform: uppercase;
			font-stretch: 75%;
			letter-spacing: -0.03em;
		}
		&-day { @include font-size(21); }
		&-date {
			@include font-size(18);
			text-transform: uppercase;
			font-weight: $body-weight-bold;
			font-stretch: 75%;
			letter-spacing: -0.03em;
		}
		&-time {
			display: block;
		}
		&-note {
			margin-top: 10px;	
		}
	}	