.form {
	font-family: $font-family-sans-serif;
	line-height: 1;
}

.form_section {
	overflow: hidden;
	margin: 0 0 30px;
	padding: 20px;
	border: 1px solid #ccc;


	.section-title {
		padding: 5px 45px 5px 10px;	
		@include font-size(20);
		font-stretch: 75%;
		font-weight: $body-weight-bolder;
		text-transform: uppercase;
		letter-spacing: -0.0275em;
	}


	hr {
		margin: 15px 0 20px;
		background-color: #c5c5c5;
	}

}

	.form-title {
		margin: 0 0 15px;
		font-weight: $heading-weight-bolder;
		span {
			font-size: 85%;
			font-weight: $heading-weight-normal;
			letter-spacing: 0.0575em;
		}
	}


	.form_group {
		position: relative;
		&:not(:last-child) { margin: 0 0 10px; }
		&.form_hp { display: none; }
		&.form_clear { clear: both; }
	}

		label {
			display: block;
			margin: 0 0 5px;
			@include font-size(18);
			font-weight: $body-weight-bold;
			font-stretch: 75%;
			line-height: 1em;
		}

		.form_control {
			display: block;
			width: 100%;
			padding: 8px;
			@include font-size(18);
			font-family: $font-family-sans-serif;
			line-height: 1;
			border: 1px solid #ccc;
			background-color: $white;
			outline: none;
			&:focus {
				border-color: $blue;
				background-color: lighten($blue, 45%);
			}	
		}

			textarea.form_control {
				min-height: 100px;
				line-height: 1.275em;
			}

	.form_error .form_control {
		border-radius: 2px 2px 0 0;
		border-color: $color-danger !important;
	}

		.error-message {
			margin: -5px 0 0;
			padding: 6px 4px 4px;
			font-size: 0.7em;
			font-weight: $body-weight-bolder;
			line-height: 1em;
			text-transform: uppercase;
			background-color: theme(danger, base);
			color: $white;
		}


		.checkbox_group {
			@include media-breakpoint-up(md) {
				display: flex;
				flex-wrap: wrap;
				.form_checkbox {
					@include make-col(1,2);
				}
			}
		}


.form_schedule,
.form_career {
	textarea.form_control {
		min-height: 150px;
	}
	@include media-breakpoint-up(md) {
		.flex_wrapper {
			@include make-flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			.form_group {
				width: 100%;
				margin: 0;
				padding: 5px 0;
			}
			.--left,
			.--right,
			.address_city { width: 49%; }
	
			.address_state,
			.address_zipcode {
				width: 23.5%;
			}
		}
	}
	/* @include media-breakpoint-only(lg) {
		.flex_wrapper {
			display: block;
			.form_group {
				width: 100% !important;
				margin-bottom: 10px !important;
			}
		}
	} */
}