.seamless-gallery {
    margin: 0 0 15px;
    line-height: 0;
    column-count: 2;
    column-gap: 15px;
    picture {
        margin-bottom: 15px;
    }
    @include media-breakpoint-up(md) {
        column-count: 3;
    }
}


.gallery_sidebar {
	@include media-breakpoint-between(xs, md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		> * {
			display: inline-block;
			@include make-col(48,100);
			margin-bottom: 3%;
		}
	}
	@include media-breakpoint-only(md) {
		> * {
			@include make-col(32,100);
			margin-bottom: 1.5%;
		}
	}
	@include media-breakpoint-up(lg) {
		> * {
			display: block;
			margin-bottom: 15px;
		}
	}
}